<template>
  <div class="card">
    <el-card shadow="hover" v-for="article in articles" :key="article.id" class="item">
      <div slot="header" @click="checkOut(article)">
        <span class="header-title">{{article.title}}</span>
      </div>
      <div class="card-body">
        <div class="body-image">
            <img :src="prefix + '/' + article.imgurl" alt="article image" class="image">
        </div>
        <div class="body-content" @click="checkOut(article)">
          <span v-html="article.content"></span>
        </div>
        <div class="clear-both"></div>
      </div>
      <div class="card-footer">
        <tag-list :tags="article.tag"></tag-list>
        <!-- <div class="time">
          <p>CREATED: <span>{{article.created_at}}</span></p>
          <p>UPDATED: <span>{{article.updated_at}}</span></p>
        </div> -->
      </div>
    </el-card>
  </div>
</template>
<script>
import { config } from '@/assets/config'
import TagList from './tagList'
export default {
  name: 'HomeList',
  components: { TagList },
  props: ['articles'],
  data () {
    return {
      prefix: config.prefix,
      colors: ['primary', 'red', 'warning', 'darkBlack', 'purple']
    }
  },
  methods: {
    checkOut (item) {
      this.$router.push({ path: '/article/' + item.id })
    }
  },
  mounted () { 
  }
}
</script>
<style lang="stylus" scoped>
.card
  margin: 20px auto
  max-width 1900px
  width 90%
  height 100%
  border none
  @media (max-width 1000px) {
    width 95%
  }
  .item
    cursor: pointer
    margin-top 17px
    .header-title
      @media (max-width 1000px) {
        text-align: center
        display: block
        width: 100%
      }
    .card-body
      .body-image
        width 500px
        height 300px
        @media (max-width 600px) {
            width 100%
            height 200px
            object-fit cover;
          }
        float left
        .image
          width 100%
          height: 100%
      .body-content
        padding 0 15px
        font-size 20px
        text-indent 40px
        max-height 270px
        overflow hidden
        cursor pointer
    .card-footer
      background-color: #FFFFFF
      .tag
        margin 10px
</style>
