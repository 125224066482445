<template>
  <div class="root">
    <div class="tags-box">
      <el-container class="tags-exists-box">
        <div class="tag-text"><p>tags</p></div>
        <div>
          <el-tag class="chip-item"
          v-for="tag in tags"
          :key="tag.id"
          :type="selectedIds.includes(tag.id) ? colors[tag.id % colors.length] : ''"
          :selected="selectedIds.includes(tag.id)" @click="selectedChange(tag)">
          {{tag.name}}
        </el-tag>
        </div>
      </el-container>
    </div>
    <div class="article-list-box">
      <home-list :articles="articles"></home-list>
    </div>
  </div>
</template>
<script>
import HomeList from '../home/components/list'
export default {
  name: 'Tags',
  components: {
    HomeList
  },
  data () {
    return {
      colors: ['primary', 'secondary', 'success', 'warning', 'info', 'error'],
      tags: [],
      articles: [],
      selectedIds: []
    }
  },
  methods: {
    getTags () {
      this.$axios.get('/api/tag')
        .then(res => {
          this.tags = res.data
          if (this.tags && this.tags.length) {
            this.selectedIds.push(this.tags[0].id)
            this.getArticlesByTag()
          }
        })
        .catch(err => { console.log(err) })
    },
    getArticlesByTag () {
      let ids = ''
      this.selectedIds.forEach(el => { ids = ids !== '0' && ids ? `${ids},${el}` : `${el}` })
      this.$axios.get('/api/tag/article/' + ids)
        .then(res => { this.articles = res.data })
        .catch(err => console.log(err))
    },
    selectedChange (tag) {
      if (this.selectedIds.includes(tag.id)) {
        this.selectedIds.splice(this.selectedIds.findIndex(el => el === tag.id), 1)
      } else {
        this.selectedIds.push(tag.id)
      }
      this.getArticlesByTag()
    }
  },
  mounted () {
    this.getTags()
  }
}
</script>
<style lang="stylus" scoped>
.root
  padding-top 70px
  .tags-exists-box
    text-align left
    width: 90%
    margin: 10px auto
    .tag-text
      font-size: 18px
  .chip-item
    margin 15px
    vertical-align middle
    font-size 20px
    cursor: pointer
</style>
